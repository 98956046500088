<template>
  <div style="max-width: unset">
    <forumsLayout></forumsLayout>
  </div>
</template>

<script>
import filesIcon from "../../components/filesIcon.vue";
import forumsLayout from "../../components/forums/forumsLayout.vue"

export default {
  components: {
    filesIcon,
    forumsLayout
  },
  data() {
    return {

    };
  },
  created() {

  },
  computed: {

  },

  methods: {

  },
};
</script>
